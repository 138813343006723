<template>
  <div class="input-number">
    <Icon type="md-remove" :class="{'disabled':minusFlag}" :color="minusFlag?'#999':''" size="22" @click="minus"/>
    <!-- @blur="inputBlur" -->
    <input
      class="input" 
      type="text" 
      :value="value"
      @blur="inputBlur"
      @input="inputChange"
      oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
    />
    <Icon type="md-add" :class="{'disabled':plusFlag}" :color="plusFlag?'#999':''" size="22" @click="plus"/>
  </div>
</template>
 
<script>
import { mapState } from 'vuex';

export default {
  name: 'CustomInputNumber',
  props:{
    // 加购数量
    value:String,
    // 活动是否开始
    isBegin:Boolean,
    // 下架商品
    unShelf:Boolean,
    // 不支持配送
    distribution:Boolean,

  },
  data () {
    return {
      plusFlag:false,
      minusFlag:true,
      maxLimit:999, //最大库存限制
    }
  },
  computed: {
    ...mapState(['goodsData','currentSkuInfos','userInfo']),
  },
  watch:{
    currentSkuInfos(){
      //计算最大加购数
      this.dealLimit()
    }
  },

  methods:{
    // 输入数量
    inputChange(e){
      this.$emit('input',e.target.value)
    },

    // 失去焦点
    inputBlur(){
      if(this.value <= 0){
        this.minusFlag = true
        this.plusFlag = this.maxLimit === 1?true:false
        this.$emit('input','1')
      }else if(this.value > this.maxLimit){
        this.plusFlag = true
        this.minusFlag = this.maxLimit === 1?true:false
        this.$Message.info('超出商品库存或限购数量，已帮您自动调整')
        this.$emit('input',String(this.maxLimit))
      }else if(this.value === '1'){
        this.minusFlag = true
        this.maxLimit === 1 && (this.plusFlag = true)
      } else if(this.value < this.maxLimit){
        this.minusFlag = false
        this.plusFlag = false
      }
    },

    plus(){
      if(this.minusFlag && this.maxLimit !== 1){
        this.minusFlag = false
      }
      let val = +this.value
      if(val >= this.maxLimit){
        this.plusFlag = true
        return this.$Message.info('不能再增加了哦')
      }
      this.$emit('input',String(++val))
    },

    // 减少按钮
    minus(){
      if(this.plusFlag && this.maxLimit !== 1){
        this.plusFlag = false
      }
      let val = +this.value
      if(val <= 1){
        this.minusFlag = true
        return this.$Message.info('不能再减少了哦')
      }
      this.$emit('input',String(--val))
    },
    
    // 获取加购最大限制数量
    dealLimit(){
      // 不可购买的商品（已下架,删除,无货,暂不支持配送）,无需校验库存
      if(this.unShelf || this.goodsData.productStatus === 30 || this.currentSkuInfos.skuInventory === 0 || this.distribution){
        this.maxLimit = Infinity
        return 
      }

      let { activityInfoVO } = this.goodsData
      //无活动 | 拼团活动过滤｜商务合作｜订金商品取skuInventory
      if(!activityInfoVO || ['30','40','70'].includes(activityInfoVO?.activityType)){
        this.maxLimit =  skuInventory
        if(this.maxLimit === 1){
          this.minusFlag = true
          this.plusFlag = true
        }
        return
      }

      const infinite = Infinity
      // 数量限制, 参与活动个人数量限制为-1则无限制，没有参与活动人数量限制无限制-1;
      // 注:null >=0 为true,空值合并运算符
      const personInventory = activityInfoVO.personInventory >= 0 ? activityInfoVO.personInventory ?? infinite : infinite
      const activityInventory = activityInfoVO.activityInventory >= 0 ? activityInfoVO.activityInventory ?? infinite : infinite
      const personLimit = activityInfoVO.personLimit >= 0 ? activityInfoVO.personLimit ?? infinite : infinite
      const skuInventory = this.currentSkuInfos.skuInventory ? this.currentSkuInfos.skuInventory : infinite
      const activities = ['10', '20', '30', '60', '70']


      // 有活动
      if ((activities.includes(activityInfoVO?.activityType) && this.isBegin) || activityInfoVO?.activityType =='60') {
        // 取活动库存、个人库存、商品库存、个人限制最小值
        this.maxLimit = Math.min(activityInventory, skuInventory, personInventory, personLimit)
      }

      if ((activityInfoVO?.activityType === '10' || activityInfoVO?.activityType === '20') && !this.isBegin) {
        this.maxLimit = Math.min(personInventory, skuInventory)
      }

      if (this.maxLimit === 1) {
        this.minusFlag = true
        this.plusFlag = true
      }
    }
  },
}
</script>
 
<style lang="less" scoped>
  .input-number{
    height: 28px;
    border: 1px solid #999;
    display: flex;
    align-items: center;
  }
  .input{
    width: 60px;
    height: 28px;
    padding: 0 5px;
    text-align: center;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    outline: red;
  }
  /deep/.ivu-icon{
    width: 28px;
    cursor: pointer;
    &.disabled{
      cursor: not-allowed;
    }
  }
  /*谷歌*/
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
    -webkit-appearance: none;
   }
   /*火狐*/
   input[type="number"] {
    -moz-appearance: textfield;
   }
</style>