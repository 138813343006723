<template>
  <div class="assess-wrap">
    <div v-if="assessList.length > 0 || isLoading || assessListPraise.length > 0">
      <div>
        <header class="assess-header" :class="{'no-assess-header': !assessList.length}" v-if="favorableRate > 0">
          <div class="good-rate">
            <span>好评率</span>
            <span>{{favorableRate}}%</span>
          </div>
          <div class="rate-btn">
            <ul class="btn-ul">
              <li
                :class="{active:cacheType === 'all'}"
                @click="getEvaluatesFn('all',true)"
              >全部({{rateObj.totalNum}})</li>
              <li
                v-if="rateObj.picNum > 0"
                :class="{active:cacheType === 'pic'}"
                @click="getEvaluatesFn('pic',true)"
              >图/视频({{rateObj.picNum}})</li>
              <li
                v-if="rateObj.newestNum > 0"
                :class="{active:cacheType === 'new'}"
                @click="getEvaluatesFn('new',true)"
              >最新(近半年)</li>
            </ul>
          </div>
        </header>
        <template v-if="changeShow">
          <div  class="assess-item" v-for="(item, i) in assessList" :key="i">
            <div class="assess-author">
              <img v-lazy="item.profile?$fillImgPath(item.profile):require('@/assets/imgs/detail/author.png')" alt=""/>
              <span class="userName">{{ item.userName }}</span>
            </div>
            <div class="assess-detail">
              <div class="attrs">规格：{{item.attributes}}</div>
              <div v-if="item.star" class="stars">
                <img
                  :src="require('@/assets/imgs/detail/star.png')"
                  v-for="(val, index) in Number(item.star)"
                  :key="index"
                />
              </div>
              <div class="text">
                <p v-if="item.evaluation">{{ item.evaluation }}</p>
                <p v-else class="tag-title black">此用户没有填写评论！</p>
              </div>
              <div class="pic-wrap" v-if="item.itemEvaluationPicVO.length>0">
                <div
                  v-for="(ele, index) in item.itemEvaluationPicVO"
                  :key="index"
                  @click="previewClick(ele,index,i)"
                  :class="{'pic-item':true,'active':ele.active}"
                >
                  <CustomVideo
                    id="vid"
                    v-if="ele.picType==20"
                    :ref="`videoRef${i}`"
                    :src="ele.picPath"
                    :controls="false"
                    :imgSize="{width:'30px',height:'30px'}"
                  />
                  <img v-else v-lazy="$fillImgPath(ele.picPath)" alt="" />
                </div>  
              </div>
              <!-- 图片/视频预览 -->
              <div v-show="previewIndexArr.includes(i)" class="preview">
                <CustomVideo
                  v-if="item.previewPicType==20"
                  :src="item.previewPicPath"
                  :playFlag="false"
                  :ref="`customVideo${i}_${cacheVideoIndex}`"
                />
                <img v-else v-lazy="$fillImgPath(item.previewPicPath)" alt="" />
              </div>
              <div class="createTime">{{ formatDateDay(item.createTime) }}</div>
            </div>
          </div>
        </template>
      </div>
      
      <!-- 默认好评 -->
      <div v-if="showDefaultItem" class="default-praise">
        <div class="praise-top">
          <span class="num">{{totalPraise > 100 ? '100+' : totalPraise}}个用户默认评价了该商品</span>
          <div class="default-btn">
            <p>已为您折叠参考价值不大的评价</p>
            <button @click="isFold = true" round >继续查看 <i></i></button>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page">
        <Page 
          :total="pageSize"
          :current.sync="currentPage"
          prev-text="上一页"
          next-text="下一页"
          @on-change="pageChange"
          @on-prev="pagePreNext"
          @on-next="pagePreNext"
        />
      </div>
    </div>
    <div v-else class="no-assess">
      <img src="@/assets/imgs/empty/content.png" alt="">
      <p>该商品暂无评价</p>
    </div>
    <Spin size="large" fix v-if="isLoading && firstEnter">加载中...</Spin>
    <Modal class="praise-dialog" v-model="isFold" title="默认评价" :footer-hide="true" width="880">
        <!-- 默认好评列表 -->
      <List
        class="goods-list-wrap"
        :loading="loadingPraise"
      > 
        <div class="assess-item" v-for="(item, i) in assessListPraise" :key="i">
          <div class="assess-author">
            <img v-lazy="item.profile?$fillImgPath(item.profile):require('@/assets/imgs/detail/author.png')" alt=""/>
            <span class="userName">{{ item.userName }}</span>
          </div>
          <div class="assess-detail">
            <div class="attrs">规格：{{item.attributes}}</div>
            <div v-if="item.star" class="stars">
              <img
                :src="require('@/assets/imgs/detail/star.png')"
                v-for="(val, index) in Number(item.star)"
                :key="index"
              />
            </div>
            <div class="text">
              <p v-if="item.evaluation">{{ item.evaluation }}</p>
              <p v-else class="tag-title black">此用户没有填写评论！</p>
            </div>
            <div class="createTime">{{ formatDateDay(item.createTime) }}</div>
          </div>
        </div>
      </List>
      <Page
        :total="praisePageSize"
        :current.sync="currentPraisePage"
        prev-text="上一页"
        next-text="下一页"
        @on-change="praisePageChange"
        @on-prev="praisePagePreNext"
        @on-next="praisePagePreNext"
      />
    </Modal>
  </div>
</template>
<script>
import {getEvaluates,getGoodRate} from '@/api/api'
import CustomVideo from './CustomVideo.vue'
import {formatDateDay} from '@/utils/common'
import { mapState } from 'vuex';
export default {
  name: '',
  components:{
    CustomVideo
  },
  data () {
   return { 
     changeShow:true,
     cacheVideoIndex:null,
     cacheVideo:null,
     playFlag:true,
     isLoading:false,
     firstEnter:true,
     showDefaultItem: false, // 展示默认好评
     pageNo: 1, //分页
     rateObj:{} ,
     cacheType:'',
     favorableRate:'', //好评率
     assessList:[] ,//评论列表
     loadingPraise: true,
     assessListPraise: [], // 默认评论列表
     pageNoPraise: 1, // 默认评价分页
     totalPraise: 0,// 默认评价总数
     isFold: false, // 是否已折叠默认好评
     pageSize:null, //分页总数
     praisePageSize: null,
     currentPage: 1,
     currentPraisePage: 1, //当前页码
     pageChangeFlag:false, //分页切换标记
     repeatFlag:false, //iview组件事件阻止
     previewIndexArr:[], // 预览缓存i
     filterBtn: [
        { type: 'all', sortType: '10', resource: '20' },
        { type: 'pic', sortType: '20', resource: '20' },
        { type: 'new', sortType: '30', resource: '' },
      ],
   }
  },
  created () {
    this.getEvaluatesFn('all')
    this.getGoodRateFn()
  },
  computed: {
    ...mapState(['goodsData','currentSkuInfos','userInfo'])
  },
  methods:{
    formatDateDay,
    // 评论数据
    async getEvaluatesFn(type = '',flag){
      this.isLoading = true
      this.showDefaultItem = false
      if(type !== this.cacheType || this.pageChangeFlag){
        this.changeShow = false
        setTimeout(() => {
          this.changeShow = true
        })
      }
      // 重复点击不请求数据
      if (type === this.cacheType && !this.pageChangeFlag) return
      if (type && !this.pageChangeFlag) {
        // 按钮点击重置分页
        this.pageNo = 1 
        this.cacheType = type 
      }
      //按钮点击重置当前页
      flag && (this.currentPage = 1)
      // sortType 10-全部 20-有图/视频 30-最新/半年内
      const currentBtn = this.filterBtn.find((ele) => ele.type === type )
      const queryData = {
        pageSize: 10,
        pageNo: this.pageNo,
        productId: this.goodsData.productNo,
        sortType: currentBtn.sortType,
        userId: this.userInfo.userId,
        skuId: this.currentSkuInfos.skuNo,
        origin: currentBtn.resource
      }
      const { result, data: resData } = await getEvaluates(queryData)
      if (result !== 0) return
      if (currentBtn.sortType == 10 && resData.totalNum !== resData.list.total) {
        // 获取默认评价数量及列表
        this.getAssessDataPraise()
        this.showDefaultItem = true
      }
      const loadList =JSON.parse(JSON.stringify(resData.list.records)) 
      const { newestNum, picNum, totalNum } = resData
      this.rateObj = {
        newestNum,
        picNum:this.formatNum(picNum),
        totalNum:this.formatNum(totalNum),
      }
      loadList.map(item => {
        item.previewPicPath = ''
        item.previewPicType = ''
        item.itemEvaluationPicVO.map(ele => {
          ele.active = false
        })
      })
      this.assessList = loadList
      this.pageSize = resData.list.total
      // 数据重置
      this.pageChangeFlag = false
      this.repeatFlag = false
      this.isLoading = false
      this.firstEnter = false
      this.previewIndexArr = []
    },
    /**
     * 请求全部列表（默认评价）
    */
    getAssessDataPraise() {
      const queryData = {
        pageSize: 10,
        pageNo: this.pageNoPraise,
        productId: this.goodsData.productNo,
        sortType: 10,
        userId: this.userInfo.userId,
        skuId: this.currentSkuInfos.skuNo,
        origin: 10
      }
      getEvaluates(queryData).then(res => {
        const { result, data: resData } = res
        if (result !== 0) return
        this.assessListPraise = resData.list.records
        this.totalPraise = resData.list.total
        this.praisePageSize = resData.list.total
        this.loadingPraise = false
      })
    },
    // 好评率
    async getGoodRateFn(){
      const {data:{favorableRate}} = await getGoodRate({productNo: this.$route.query.productNo})
      this.favorableRate = favorableRate
    },
    praisePageChange(i) {
      this.pageNoPraise = i
      this.getAssessDataPraise()
    },
    praisePagePreNext(i) {
      this.pageNoPraise = i
      this.getAssessDataPraise()
    },
    // 页码切换
    pageChange(i){
      this.repeatFlag = true
      this.pageNo = i
      this.pageChangeFlag = true
      this.getEvaluatesFn(this.cacheType)
      this.getAssessDataPraise()
    },
    // 上一页下一页
    pagePreNext(i){
      /* 
        iview分页组件的页面切换事件和上/下页事件会重复触发
        处理避免重复请求
      */
      if(this.repeatFlag) return
      this.pageNo = i
      this.pageChangeFlag = true
      this.getEvaluatesFn(this.cacheType)
    },
    // 图片/视频预览
    previewClick(ele,index,i){
      if(ele.picType === '10'){
        ele.showImg = true
      }else{
        const videoDom = this.$refs[`videoRef${i}`]
        const videoArr = videoDom.filter(item => item.src !== ele?.picPath)
        videoArr.map(item => {
          item.showImg = true
        })
      }

      if(ele.active){
        this.assessList[i].itemEvaluationPicVO[index].active = false
        this.$nextTick(() => {
          const index = this.previewIndexArr.findIndex(item => item === i)
          this.previewIndexArr.splice(index,1)
        })
        return
      }
      // 重置style
      this.assessList[i].itemEvaluationPicVO.map(item => {
        item.active = false
      })
      
      this.assessList[i].itemEvaluationPicVO[index].active = true
      this.assessList[i].previewPicPath = ele.picPath
      this.assessList[i].previewPicType = ele.picType
      if(!this.previewIndexArr.includes(i)){
        this.previewIndexArr.push(i)
      }
      if(ele.picType === '10') return
      this.cacheVideoIndex = index
      this.$nextTick(() => {
        const curVideo = this.$refs[`customVideo${i}_${index}`][0]
        curVideo.load()
        curVideo.$refs.vid.addEventListener('ended',curVideo.resetImg,false)
        curVideo.play()
      })
    },
    //重置选中样式
    resetStyle(arr){
      loadList.map(item => {
        item.itemEvaluationPicVO.map(ele => {
          ele.active = false
        })
      })
    },
    //格式数据
    formatNum(val) {
      if (val >= 10000) {
        let value = Number(val / 10000)
        const num = parseInt(value * 10) / 10
        return `${num}万+`;
      }
      return val;
    },
  }
}
</script>
 
<style lang="less" scoped>
  .assess-wrap{
    color: #000;
    padding-bottom: 50px;
    position: relative;
  }
  .assess-header{
    display: flex;
    padding: 40px 0;
    align-items: center;
    .good-rate{
      width: 136px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid #999;
      span{
        &:last-child{
          font-size: 34px;
          color: #ef2f2f;
          margin-top: 6px;
        }
      }
    }
    .rate-btn{
      .btn-ul{
        display: flex;
      }
      li{
        width: 110px;
        height: 28px;
        background-color: #fff;
        border-radius: 3px;
        text-align: center;
        line-height: 28px;
        margin-left: 24px;
        font-size: 14px;
        cursor: pointer;
        &.active{
          border: 1px solid #ef2f2f;
          color: #ef2f2f;
          background: rgba(239,47,47,0.05);
        }
      }
    }
  }
  .no-assess-header {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .assess-item{
    display: flex;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #999;
    padding: 16px 0;
    .assess-author{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 38px;
      width: 60px;
      img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      span{
        margin-top: 16px;
        font-size: 12px;
      }
    }
    .assess-detail{
      flex: 1;
      .attrs{
        color: #999;
        font-size: 12px;
        margin-bottom: 8px;
      }
      .stars{
        img{
          width: 20px;
          height: 20px;
        }
      }
      .text{
        font-size: 14px;
        line-height: 1.5;
        margin-top: 5px;
        p {
          word-break: break-all;
        }
      }
      .pic-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
      }
      .pic-item{
        width: 60px;
        height: 60px;
        margin-right: 8px;
        &.active{
          border: 1px solid #ff4d4f;
        }
        img{
          width: 100%;
          height: 100%;
        }
      }
    }

    .createTime{
      color: #999;
      font-size: 14px;
      margin-top: 10px;
    }

    .preview{
      width: 300px;
      height: 400px;
      margin-top:10px;
      img,video{
        width: 100%;
        height: 100%;
      }
    }
  }

  .page{
    display: flex;
    margin-top: 50px;
  }
  .praise-dialog {
    .assess-item {
      width: 98%;
      padding-left: 10px;
    }
    /deep/ .ivu-modal-header {
      width: 98%;
      margin: 0 auto;
      .ivu-modal-header-inner {
        font-weight: 400;
        color:#333;
      }
    }
    /deep/ .ivu-modal-body {
      padding: 0px;
    }
    /deep/ .ivu-list-items {
      height: 670px;
      overflow: auto;
    }
    /deep/ .ivu-page {
      padding-bottom:16px;
    }
  }

  /deep/ .ivu-page {
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
  }
  /deep/ .ivu-page-prev {
    margin-right: 20px;
  }
  /deep/ .ivu-page-next {
    margin-left: 20px;
  }
  /deep/ .ivu-page-item-active {
    border-color: #CC0000;
    background-color: #CC0000;
  }
  /deep/ .ivu-page-item-active a {
    color: #fff;
  }
  .no-assess{
    color: #999;
    text-align: center;
    padding-top: 150px;
    img {
      width: 200px;
      height: 200px;
    }
  }

  /deep/.ivu-spin-main{
    top: 200px;
    color: #999;
  }
.default-praise {
  width: 90%;
  margin: 0 auto;
  padding-top: 55px;
  .praise-top {
    font-size: 14px;
    text-align: center;
    color: #999;
  }
  .default-btn {
    p {
      margin-top: 10px;
    }
    button {
      cursor: pointer;
      position: relative;
      margin-top: 7px;
      font-size: 12px;
      color: #999;
      width: 98px;
      height: 26px;
      opacity: 1;
      border: 1px solid #999999;
      border-radius: 15px;
      &:hover {
        i {
          border: 1px dashed #999;
        }
      }
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        background: url('~@/assets/imgs/detail/arrow.png') no-repeat;
        background-size: 100%;
      }
    }
  }
}
</style>
