<template>
  <div class="wrap-detail">
    <div class="detail">
      <ul class="tag-ul" :style="headHeight">
        <li :class="['tag-li',activeIndex === index?'active':'']" v-for="(item,index) in tags" :key="index" @click="handleClick(index,item)">{{item.title}}</li>
      </ul>
      <div class="content-wrap">
        <div :class="['content-inner', getClass]">
          <keep-alive>
            <component :is="componentName" :typeFlag="typeFlag"/>
          </keep-alive>
        </div>
      </div>
    </div>

    <!-- 相关好物 -->
    <div class="recommend">
      <Recommend>
        <template slot="title">
          <div class="title-content">
            <img class="icon" src="@/assets/imgs/detail/ic_star@2x.png" alt="">
            <span>相关好物</span>
          </div>
        </template>
      </Recommend>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import RenderHtml from './RenderHtml.vue'
import Assess from './Assess.vue'
import SpecsParams from './SpecsParams.vue'
import Recommend from '@/components/Recommend.vue'

export default {
  name: 'ShowGoodsDetail',
  components:{
    RenderHtml,
    Assess,
    SpecsParams,
    Recommend
  },
  data () {
    return {
      componentName:'RenderHtml',
      activeIndex:0,
      typeFlag:'detail',
      firstEnter:true,
      tags:[
        {title:'商品详情',name:'RenderHtml',type:'detail'},
        {title:'评价',name:'Assess'},
        {title:'规格参数',name:'SpecsParams'}
      ],
      headHeight: ''
    }
  },
  computed: {
    ...mapState(['goodsData']),
    getClass() {
      let classStr = ''
      if (this.componentName == 'Assess') {
        classStr = 'assess'
      }
      if (this.componentName == 'SpecsParams') {
        classStr = 'specs-Params'
      }
      return classStr
    }
  },
  watch:{
    'goodsData.serviceInfo':{
      handler(val){
        if(!this.firstEnter) return
        if(val?.serviceContent){
          this.tags.push({title:val.serviceName || '安装服务', name:'RenderHtml',type:'serviceContent'})
        }
        if(val?.policyContent){
          this.tags.push({title:val.policyName || '售后政策', name:'RenderHtml',type:'policyContent'})
        }
        this.firstEnter = false
      },
    }
  },
  methods:{
    handleClick(index,item){
      const contentWrap = document.getElementsByClassName('wrap-detail');
      window.scrollTo({top: 95 + contentWrap[0].offsetTop - 40}) //contentWrap[0].offsetTop是元素到最近有定位的父元素的距离 95是顶部元素高度 40是自身元素margin-top
      this.activeIndex = index
      this.componentName = item.name
      if(!item.type) return
      this.typeFlag = item.type
    }
  },
  mounted() {
    const detail = document.getElementsByClassName('header')[0]
    this.headHeight = {
      top: detail.offsetHeight + 'px'
    }
  }
}
</script>

<style lang="less" scoped>
  .wrap-detail{
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
  }

  .detail{
    margin-right: 40px;
    width: 856px;
    height: 100%;
  }

  .tag-ul{
    height: 40px;
    display: flex;
    background-color: #fff;
    z-index: 8;
    position:sticky;
  }

  .tag-li{
    width: 136px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    &.active{
      background-color: #cc0000;
      color: #fff;
    }
  }

  .content-wrap{
    width: 100%;
    border: 1px solid #999;
  }

  .content-inner{
    width: 90%;
    margin: 0 auto;
    height: 100%;
    min-height: 500px;
    &.assess{
      width: 100%;
    }
    &.specs-Params {
      width: 100%;
    }
  }

  .recommend{
    position: absolute;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: #fff;
    overflow: auto;
    /deep/ .goods-inner {
      align-items: center;
      flex-direction: column;
    }
    .title-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: FZLanTingHeiS-M-GB, FZLanTingHeiS-M-GB-Regular;
      font-size: 14px;
      color: #333;
      font-weight: 400;
      .icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
      &::before {
        position: absolute;
        left: 65px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 1px;
        content: '';
        background: rgba(153,153,153,0.60);
      }
      &::after {
        position: absolute;
        right: 65px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 30px;
        height: 1px;
        background: rgba(153,153,153,0.60);
      }
    }
  }
</style>
