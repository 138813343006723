<template>
  <div class="goods-wrap">
    <template v-if="defaultFlag ||  [30].includes(this.goodsData.productStatus)">
      <!-- 删除状态-缺省图 -->
      <div class="no-exist">
        <img src="@/assets/imgs/detail/no-exist.png" alt="">
        <span>商品不存在</span>
        <div class="btn" @click="$router.push('./home')">去逛逛其他商品 ></div>
      </div>
    </template>
    <template v-else>
      <!-- 商品信息 -->
      <ShowGoods ref="goods"/>
       <!-- 对比栏 -->
      <ComparisonColumn origin="detail"/>
      <!-- 商品详细 -->
      <ShowGoodsDetail />
    </template>
    <!-- 加载loading -->
    <Spin size="large" fix v-if="isLoading"></Spin>
  </div>
</template>

<script>
import ShowGoods from './goods-detail/ShowGoods.vue';
import ShowGoodsDetail from './goods-detail/ShowGoodsDetail.vue';
import ComparisonColumn from '@/components/ComparisonColumn.vue'; 
import { insertBrowHistory } from '@/api/api'
import { mapMutations, mapState, mapActions } from 'vuex';
export default {
  name: 'GoodsDetail',
  components:{
    ShowGoods,
    ShowGoodsDetail,
    ComparisonColumn
  },
  data () {
    return {
      defaultFlag:false,
      isFromLogin: {
        isPass: false
      }
    };
  },
  async created () {
    const { productNo, skuNo } = this.$route.query
    // 加载详情数据
    const data = {
      productNo, 
      skuNo,
      userId: this.userInfo.userId
    }
    // const {productNo,skuNo} = this.$route.query
    // if(productNo && skuNo){
    //   this.loadGoodsInfo({productNo,skuNo,userId:this.userInfo.userId});
    // }else{
    //   this.defaultFlag = true
    // }
    await this.loadGoodsInfo(data);
    // 增加浏览记录
    this.browHistoryFn()
  },
  mounted() {
    this.recoveryAction()
  },
  computed: {
    ...mapState(['isLoading','goodsData','userInfo', 'currentSkuInfos'])
  },
  methods: {
    ...mapMutations('comparisonColumn', ['InsertHistoryList']),
    ...mapActions(['loadGoodsInfo']),
    /*
     * 恢复登录之前的操作 
    */ 
    recoveryAction () {
      const _this = this
      const { isPass, action } = this.isFromLogin
      if (!isPass) return
      this.isFromLogin = {}
      switch (action) {
        case 'buy': // 立即购买
          _this.$refs.goods.buyNow()
          break
        case 'add': // 加入购物车
          _this.$refs.goods.addCart()
          break
      }
    },
    refreshCartNum () {
      this.$emit('cart');
    },
    browHistoryFn() {
      const { skuNo } = this.$route.query
      // 未登录加入本地浏览记录
      if (!this.userInfo.userId) {
        const { skuName, skuAdPicture, currentPrice, isShowLabel, skuState } = this.currentSkuInfos
        const price = this.goodsData.activityInfoVO? this.goodsData.activityInfoVO.activityPrice : currentPrice
        const goods = {
          shopId: this.goodsData.shopId,
          productNo: this.goodsData.productNo, 
          skuNo,
          status: skuState,
          price,
          thumbUrl: skuAdPicture,
          name: skuName,
          thirdPlatformCid: this.goodsData.cid,
          date: new Date().getTime()
        }
        if (isShowLabel) {
          this.InsertHistoryList(goods)
        }
        return
      }
      
      const params = {
        userId: this.userInfo.userId,
        baseShopId: this.goodsData.shopId,
        itemId: this.goodsData.productNo,
        skuId: skuNo
      }
      insertBrowHistory(params).then(res=> {
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    const { path, query : { action } } = from
    if (path === '/login') {
      next(vm => {
        if (!vm.userInfo.userId) return
        vm.isFromLogin.isPass = true
        vm.isFromLogin.action = action
      })
    }
    next()
  }
};
</script>

<style lang="less" scoped>
.goods-wrap{
  width: 1200px;
  margin: 15px auto;
}
.no-exist{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  img{
    width: 200px;
    height: 200px;
  }
  span{
    margin: 20px 0;
  }
  .btn{
    border: 1px solid #ccc;
    text-align: center;
    width: 150px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
    cursor: pointer;
  }
}
/deep/.ivu-spin{
  z-index: 99999;
}
</style>
