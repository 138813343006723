<template>
  <div class="render-wrap">
    
    <div class="video-wrap" v-if="goodsData.itemDetailVideoUrl && typeFlag === 'detail'">
      <CustomVideo 
        :src="goodsData.itemDetailVideoUrl"
        :poster="goodsData.itemDetailVideoPicUrl"
      />
    </div>
    <!-- 店铺上广告 -->
    <div v-if="typeFlag == 'detail' && upLowAdvertiseArr" class="main-content" v-html="upLowAdvertiseArr[0]"></div>
    <!-- 共用html渲染 -->
    <div class="main-content" v-html="fillDescribeUrl"></div>

    <div v-if="typeFlag == 'detail' && upLowAdvertiseArr" class="main-content" v-html="upLowAdvertiseArr[1]"></div>
  </div>
</template>
 
<script>
import { mapState } from 'vuex';
import config from '@/config'
import { preFixUrl } from '@/utils/common'

import CustomVideo from './CustomVideo.vue'
export default {
  name: 'RenderHtml',
  components:{
    CustomVideo
  },
  props:{
    typeFlag:{
      type:String,
      default:''
    }
  },
  data () {
    return {
    }
  },
  computed:{
    ...mapState(['goodsData']),
    
    fillDescribeUrl(){
      let res = this.typeFlag === 'detail'? this.goodsData.describeUrl?.replace(/src=\"\//g,`src="${config.PIC_URL}/`) 
             : this.goodsData.serviceInfo[this.typeFlag]
      return preFixUrl(res)
    },
    upLowAdvertiseArr() {
      const fillUrl = `src="${config.PIC_URL}/`
      return this.goodsData.shopAd?.map(item => {
        return preFixUrl(item.url)?.replace(/src=\"\//g, fillUrl)
      })
    }

  }
}
</script>
 
<style lang="less" scoped>
  
  .render-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .video-wrap{
    width: 450px;
    height: 300px;
    margin: 0 auto;
  }

  .main-content{
    width: 100%;
    /deep/img{
      width: 100%;
      outline-width:0px;
      vertical-align:top;
    }
  }
</style>