import { render, staticRenderFns } from "./SpecsParams.vue?vue&type=template&id=72f67824&scoped=true"
import script from "./SpecsParams.vue?vue&type=script&lang=js"
export * from "./SpecsParams.vue?vue&type=script&lang=js"
import style0 from "./SpecsParams.vue?vue&type=style&index=0&id=72f67824&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f67824",
  null
  
)

export default component.exports