<template>
  <div class="bottom-line">
    <div v-if="goodsData.specsParamUrl" class="specs-paramUrl">
      <div class="title">外观尺寸</div>
      <img class="img" v-lazy="$fillImgPath(goodsData.specsParamUrl)" alt=""/>
    </div>
 
    <div v-if="attr.length > 0" class="product-params">
      <ul class="list">
        <li
          v-for="(item, i) in attr"
          :key="i"
          style="margin-top: 24px;"
        >
          <span v-if="item.attrGroupName" class="group-name title">{{ item.attrGroupName }}</span>
          <ul>
            <li class="list-item" v-for="(attr, attrIndex) in item.attrDetail" :key="attrIndex">
              <span >{{ attr.cKey }}</span>
              <span >{{ attr.attrValue }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'SpecsParams',
  data () {
    return {
      specsParamsInfo: {}
    }
  },

  computed:{
    ...mapState(['goodsData', 'currentSkuInfos']),
    attr() {
      let attr = []
      this.specsParamsInfo = this.goodsData.skuInfoList.find(el => {
        return el.skuNo === this.currentSkuInfos.skuNo
      })
      if (this.specsParamsInfo.attrGroupDetail.length > 0) {
        attr = this.specsParamsInfo.attrGroupDetail
      } else {
        attr = this.specsParamsInfo.attrContrastDetail
      }
      return attr
    }
  }
}
</script>

<style lang="less" scoped>

  .bottom-line {
    text-align: left;
  }

  .title {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }

  .img{
    width: 100%;
    max-height: 350px;
  }
  .specs-paramUrl {
    .title {
      margin:16px 0 12px 24px;
    }
  }
  .product-params {
    margin-top: 24px;
    .title {
      margin-left: 24px;
    }
  }
  .list {
    width: 100%;
    color: #666666;
    font-size: 12px;
    .group-name {
      margin-top: 12px;
    }
    .list-item:not(:last-child) {
      margin-bottom: 8px;
    }
    .list-item:first-child {
      margin-top: 12px;
    }
    .list-item {
      display: flex;
      line-height: 46px;
      text-emphasis: e;
      span:nth-child(1) {
        width: 251px;
        text-align: right;
        background:#fff;
        color: #999;
        padding-right: 8px;
        margin-right: 8px;
      }
      span:nth-child(2) {
        width: calc(100% - 251px);
        text-align: left;
        background:#fff;
        font-weight: 400;
        padding-left: 8px;
        color: #333;
      }
      span{
        width: 50%;
        word-break: break-all;
      }
    }
  }

</style>
