<template>
  <div class="sku-wrap">
    <div class="btn-wrap" v-for="(item, index) in attrDTO" :key="index">
      <span class="attr-title">{{item.value}}</span>
      <div class="attr-list">
        <template v-for="(attr, j) in item.attrValueDTO">
          <span
            v-if="attr.stock !== undefined"
            :key="j"
            :class="{'attr-item':true,
            'active':selectArr[index] === attr.valueName,
            }"
            @click="selectAttr(attr, index)"
          >
            {{attr.valueName}}
            <span v-show="attr.stock == 0 && !activeFlag">(缺货)</span>
          </span>
        </template>
      </div>
    </div>

    <div class="quantity-wrap">
      <span class="quantity-title">数量</span>
      <CustomInputNumber 
        v-model="quantity"
        v-bind="$attrs"
        ref="inputNumber"
      />
    </div>
  </div>
</template>
 
<script>
import { mapState } from 'vuex';
import { changeURLStatic } from '@/utils/common'
import CustomInputNumber from './CustomInputNumber'

export default {
  name: 'SkuPanel',
  components:{
    CustomInputNumber
  },
  data () {
    return {
      attrDTO:[], //展示的sku信息
      selectArr:[], //选择的sku
      quantity:'1', //数量
      maxLimit:6, //最大库存限制
      specsObj:{},
    } 
  },
  watch:{
    currentSkuInfos(){
      this.selectArr = []
      this.specsObj = {}
      this.initData()
      this.setSpecsAttr()
    }
  },
  computed: {
    ...mapState(['goodsData','currentSkuInfos','userInfo']),

    // 活动商品不展示缺货
    activeFlag(){
      return  this.goodsData?.activityInfoVO && [10, 20, 40, 70].includes(+this.goodsData.activityInfoVO.activityType)
    }
  },
  methods:{
    changeURLStatic,
    // 初始化数据
    initData() {
      // 获取所有属性的key值
      this.allKeys = this.getAllKeys();
      // 重新组合sku
      this.skuList = this.combineAttr(this.goodsData.attrSku, this.allKeys);
      this.attrDTO = JSON.parse(JSON.stringify(this.goodsData.attrDTO))
      this.attrDTO.forEach(it => {
        it.attrValueDTO.forEach(i => {
          this.$set(i, 'children', [])
          this.skuList.forEach(item => {
            if (item.path.includes(i.valueName)) {
              i.children.push(item.path)
            }
          })
        })
      })
      // 初始化判断库存
      this.currentSkuInfos.itemSaleAttributeList.forEach(j => {
        this.selectArr.push(j.attrValue);
        this.getData(this.selectArr, this.attrDTO, this.skuList) // 校验库存
      })
      
    },

    // 循环判断哪些sku组合库存为空
    getData(selectArr, attrDTO, skuList) {
      attrDTO.forEach((item, index) => {
        item.attrValueDTO.forEach((it) => {
          let c = JSON.parse(JSON.stringify(selectArr))
          // 这里进行循环组合， 例如：红色_5.5寸，红色_6.0寸
          c[index] = it.valueName
          // 拿到我们想要的sku组合，例如：红色_5.5寸
          let skuName = this.getCid(c, attrDTO, skuList)
          // 根据我们拿到的sku组合 红色_5.5寸进行查库存
          let stock = this.getStock(skuName, attrDTO, skuList)
          it.stock = stock
        })
      })
    },

    getCid(c, attrDTO, skuList) {
      // 首先进行判断，避免单纯的红色，蓝色也进行循环。
      if (c.length !== attrDTO.length) return
      let cStr = c.join('_')
      // 对后台传来的sku组合进行循环遍历,若匹配上，则返回这个组合的skuName，例如：红色_5.5寸 
      for (let i in skuList) {
        let arr1 = skuList[i].path.split('_');
        let arr2 = cStr.split('_');
        if (this.equalArray(arr1, arr2)) {
          return skuList[i].path
        }
      }
    },
    // 查询库存
    getStock(skuName, attrDTO, skuList) {
      if (!skuName) return
      for (let a in attrDTO) {
        for (let b in attrDTO[a].attrValueDTO) {
          // 对每项Spu中children进行循环，若此children中包含skuName，则返回当前组合的库存到当前Spu中
          // 例如点击红色时，遍历匹配到红色_5.5寸，红色库存则为红色_5.5寸的库存 300,5.5寸此时库存也为300。以此类推可以得出6.0寸的库存。
          if (attrDTO[a].attrValueDTO[b].children.indexOf(skuName) !== -1) {
            for (let i in skuList) {
              if (skuList[i].path == skuName) {
                return skuList[i].skuInventory
              }
            }
          }
        }
      }
    },
    // 判断数组是否相等
    equalArray(arr1, arr2) {
      let flag = true
      if (arr1.length !== arr2.length) {
        flag = false
      } else {
        arr1.forEach(item => {
          if (arr2.indexOf(item) === -1) {
            flag = false
          }
        })
      }
      return flag;
    },

    // 获取所有属性key
    getAllKeys() {
      return this.goodsData.attrDTO.reduce((pre,cur) => [...pre,cur.value],[])
    },

    /**
     * 计算属性
     * @param  {[type]} attrSku [在售sku规格列表]
     * @param  {[type]} keys [所有的属性key值]
     * @return {[type]}      [description]
     */
    combineAttr(attrSku, keys) {
      let allKeys = []
      for (let i = 0; i < attrSku.length; i++) {
        let item = attrSku[i]
        let values = []
        for (let j = 0; j < keys.length; j++) {
          let key = keys[j]
          values.push(item[key]);
        }
        allKeys.push({
          path: values.join('_'),
          sku: item['skuNo'],
          skuInventory:item['skuInventory']
        });
      }
      return allKeys
    },

    // 切换sku
    selectAttr(attr, index) {
      // 选中的规格数组组成:[蓝色，5.5寸]
      if (this.selectArr[index] == attr.valueName) {
          return
      } else{
        this.$set(this.selectArr, index, attr.valueName)
      }
      
      if (this.selectArr.length == this.attrDTO.length) {
          // 选取了所有的规格时 拿去skuId
          let str = ''
          this.selectArr.forEach(item => {
              str += '_' + item
          })
          str = str.slice(1)
          // 所有规格都选了时，进行匹配skuId，此处数据用于传给后台
          this.skuList.forEach(item => { // 匹配skuId
              if (item.path === str) {
                this.skuId = item.sku
              }
          })
      }
      // 校验库存
      this.getData(this.selectArr, this.attrDTO, this.skuList) 
      this.specsObj[attr.attrId] = attr.valueId
      // 过滤点击的skuNo
      let attrStr = ''
      for (let key in this.specsObj) {
        attrStr += key + ':' + this.specsObj[key] + ';'
      }
      let resObj = this.goodsData.skuInfoList.filter(item => item.attributes.split(';').sort().toString() === attrStr.split(';').sort().toString()
      )
      // 刷新详情数据
      const data = {
        productNo: this.goodsData.productNo,
        skuNo: resObj[0].skuNo,
      }
      Object.assign( this.$route.query, { 'skuNo': resObj[0].skuNo })
      changeURLStatic('skuNo', resObj[0].skuNo)
      this.$store.dispatch('loadGoodsInfo', data)
      this.$refs.inputNumber.plusFlag = false
      this.$refs.inputNumber.minusFlag = true

      if(this.quantity === '1') return
      this.quantity = '1'
    },

    // 设置规格属性
    setSpecsAttr() {
      let attrArr = this.currentSkuInfos.attributes.split(';')
      attrArr.forEach(item => {
        if (item) {
          let arr = item.split(':')
          this.$set(this.specsObj, arr[0], arr[1])
        }
      })
    },

  }
}
</script>
 
<style lang="less" scoped>
.sku-wrap{
  padding: 10px 0 10px 15px;
}
.btn-wrap{
  line-height: 28px;
  color: #999;
  padding: 10px 0;
  .attr-title{
    width: 40px;
    text-align: justify;
    text-align-last: justify;
    float: left;
    white-space: nowrap;
  }
  .attr-list {
    margin-left: 66px;
  };
  .attr-item{
    display: inline-block;
    border: 1px solid #999;
    min-width: 60px;
    margin-bottom: 5px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    margin-right: 10px;
    padding: 0 8px;
    &.active{
      border-color: #FF4D4F;
      color: #FF4D4F;
    }
  }
}
.quantity-wrap{
  line-height: 28px;
  margin-top: 10px;
  .quantity-title{
    width: 40px;
    float: left;
    color: #999;
    text-align: justify;
    text-align-last: justify;
  }
  /deep/ .input-number {
    margin-left: 66px;
    width: max-content;
  }
}
</style>