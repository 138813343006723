<template>
  <div class="custom-video">
    <div v-show="showImg" class="play-img" @click="handleClick">
      <img 
        src="@/assets/imgs/detail/play.png" 
        :style="styleObj"   
        alt=""
      >
    </div>
    <div class="video-player">
      <video
        ref="vid"
        preload="auto"
        :poster="realPoster"
        :controls="controls"
        playsinline="true"
        x5-video-orientation="portraint"
        controlslist="nodownload"
        disablePictureInPicture
      >
        <source :src="$fillImgPath(src)" type="video/mp4" />
      </video>
    </div>
  </div>
</template>
 
<script>
 
export default {
  name: 'CustomVideo',
  props:{
    //视频源url
    src:{
      type:String,
      default:''
    },

    //视频封面
    poster:{
      type:String,
      default:''
    },

    //是否展示控制按钮
    controls:{
      type:Boolean,
      default:true
    },

    //是否展示播放按钮
    playFlag:{
      type:Boolean,
      default:true
    },

    //播放按钮大小
    imgSize:{
      type:Object,
      default:() => {
        return {
          width:'80px',
          height:'80px'
        }
      }
    }
    
  },
  data(){
    return {
      showImg: this.playFlag,
      flag: true
    }
  },
  watch:{
    src(val){
      // 视频源变化
      this.showImg = false
    }
  },
  computed: {
    realPoster(){
      return this.poster ? this.$fillImgPath(this.poster) : `${this.$fillImgPath(this.src)}?x-oss-process=video/snapshot,t_1000,f_jpg`
    },
    styleObj(){
      return this.imgSize
    }
  },
  methods:{
    handleClick() {
      setTimeout(() => {
        this.showImg = !this.showImg
        if (!this.showImg) {
          this.play()
        }
        if (this.showImg) {
          this.pause()
        }
      },100)
    },
    pause() {
      this.$refs.vid.pause()
    },
    load(){
      this.$refs.vid.load()
    },
    play(){
      this.$refs.vid.play()
    },
    resetImg(){
      this.showImg = true
    }
  }
}
</script>
 
<style lang="less" scoped>
  .custom-video{
    width: 100%;
    height: 100%;
    position: relative;
  }

  .play-img{
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .video-player{
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    video {
      width: 100%;
      height: 100%;
    }
  }

  video::-webkit-media-controls-fullscreen-button {
    display:none !important;
  }
</style>