<template>
  <div class="btn-wrap">
    <!-- 按钮状态 7种 优先级：1下架>2商务合作>3已抢光>4无货>5暂不支持配送>6支付订金>>7正常销售 -->
    <!-- 优先级问题，故下架的ui不能复用 -->
    <template v-if="unShelf">
      <div class="btn light hoverGray">加入购物车</div>
      <div class="btn deep hoverLight" @click="gotoSimilar">查看类似商品</div>
    </template>
    <!-- 商务合作 -->
    <template v-else-if="businessGood">
      <div class="btn Purchase hoverGray" @click="modal = true">购买咨询</div>
    </template>
     <!-- 活动-已抢光 -->
    <template v-else-if="lootAll">
      <div class="btn light">已抢光</div>
      <div class="btn deep hoverLight" @click="gotoSimilar">查看类似商品</div>
    </template>
    <!-- sku无货 -->
    <template v-else-if="noInventory">
      <div class="btn light hoverGray">加入购物车</div>
      <div class="btn deep hoverLight" @click="gotoSimilar">查看类似商品</div>
    </template>
    <!-- 不支持配送 -->
    <template v-else-if="distribution">
      <div class="btn light distribution hoverGray">暂不支持配送</div>
    </template>

    <!-- 订金 -->
    <template v-else-if="isDeposit">
      <div class="isDeposit" @click="modal = true">
        <div>
          <span>支付订金</span>
          <span class="price">( ¥ {{depositPrice}} )</span>
        </div>
        <!-- <span>签订合同后线下转账支付尾款</span> -->
      </div>
    </template>
    <!-- 正常 -->
    <template v-else>
      <div class="btn hoverGray" @click="handleClick('add')">加入购物车</div>
      <div class="btn deep hoverLight" @click="handleClick('buy')">{{buyText}}</div>
    </template>

    <div class="collect-btn">
      <Icon v-if="hasFavorite" @click="handleCollect(true)" type="md-star" color="#F4A125" size="23"/>
      <Icon v-else @click="handleCollect(false)" type="md-star-outline" color="#999" size="23"/>
      <span>{{ hasFavorite? '已收藏':'收藏'}}</span>
    </div>
    <div v-if="currentSkuInfos.isShowLabel" class="compare-btn" @click="handleCompare">
      <i :class="{ 'active': isCompare(currentSkuInfos) }"></i>
      <span>对比</span>
    </div>
    <Modal
      v-model="modal"
     >
      <img src="@/assets/imgs/detail/mini.png" alt="">
      <div slot="footer">
        请打开微信扫一扫，进行下一步操作吧
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import{addCollect,removeCollect} from '@/api/api'

export default {
  name: '',
  components:{

  },
  props:{
    businessGood:Boolean,
    isDeposit:Boolean,
    noInventory:Boolean,
    unShelf:Boolean,
    isBegin:Boolean,
    distribution:Boolean,
    footerClick:Function
  },
  data () {
   return {
     modal:false
   }
  },
  computed: {
    ...mapGetters('comparisonColumn', ['isCompare']),
    ...mapState(['goodsData','currentSkuInfos','userInfo']),
    hasFavorite() {
      return this.goodsData?.favoriteId? 1 : 0
    },
    //已抢光按钮
    lootAll() {
      const activeInfo = this.goodsData?.activityInfoVO
      if(['10', '20'].includes(activeInfo?.activityType) && this.isBegin){
        return activeInfo.activityInventory === 0 || this.currentSkuInfos.skuInventory === 0
      }
      return false
    },
    buyText() {
      let text = '立即购买'
      const activeInfo = this.goodsData?.activityInfoVO
      if (['10','20'].includes(activeInfo?.activityType)) {
        text = '立即抢购'
      }
      return text
    },
    depositPrice() {
      const { activityPrice } = this.goodsData?.activityInfoVO
      if (activityPrice.indexOf('.') != -1) {
        const num = activityPrice.split('.')[0]
        const float = activityPrice.split('.')[1]
        return float > 0? `${num}.${float}` : num
      }
      return activityPrice
    }
  },
  methods:{
    ...mapMutations('comparisonColumn', ['InsertGoodsList','DeleteGoodsList', 'SetVisibility', 'ToggleTab' ]),
    handleClick(type){
      this.$emit('footerClick',type)
    },
    // 收藏
    handleCollect(flag){
      if (!this.userInfo.userId) {
        // 进入登录页
        this.$router.push({
          path: '/login',
          query: { back: window.location.href }
        })
        return
      }
      !flag ? this.addCollectFn() : this.removeCollectFn()
    },
    // 添加收藏
    async addCollectFn (){
      const reqData = {
          itemId: this.goodsData.productNo,
          skuId: this.currentSkuInfos.skuNo,
          userId: this.userInfo.userId,
          baseShopId: this.goodsData.shopId
        }
      const { result, data: { result: favoriteId } } = await addCollect(reqData)
      if (result === 0) {
        this.goodsData.favoriteId = String(favoriteId)
        this.$Message.success('收藏成功');
      }
    },
    // 取消收藏
    async removeCollectFn (){
      const reqData = {
        ids: [this.goodsData.favoriteId],
        userId:this.userInfo.userId
      }
      const { result } = await removeCollect(reqData)
      if (result === 0) {
        this.goodsData.favoriteId = ''
        this.$Message.success('取消收藏成功');
      }
    },
    // 去客服
    gotoServe(){
      // this.$router.push()
    },
    // 去相似
    gotoSimilar(){
      this.$router.push({
        name: 'Similar',
        params: {
          skuId: this.currentSkuInfos.skuNo
        }
      })
    },
    handleCompare() {
      const { skuNo, skuName, currentPrice, skuAdPicture, skuState } = this.currentSkuInfos
      let price = this.goodsData.activityInfoVO? this.goodsData.activityInfoVO.activityPrice : currentPrice
      const goods = {
        shopId: this.goodsData.shopId,
        productNo: this.goodsData.productNo,
        skuNo,
        status: skuState,
        price,
        thumbUrl: skuAdPicture,
        name: skuName,
        thirdPlatformCid: this.goodsData.cid
      }
      let isExist = this.isCompare(this.currentSkuInfos)
      // 显示对比栏
      if (!this.$store.state.comparisonColumn.visibility && isExist) {
        this.SetVisibility(true)
        return
      }
      if (!isExist) {
        this.SetVisibility(true)
        this.InsertGoodsList({ goods })
        this.ToggleTab(0)
      }
      if (isExist) {
        this.DeleteGoodsList(goods)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .btn-wrap{
    display: flex;
    margin-top: 20px;
    margin-left: 16px;
    .btn{
      border: 1px solid #EF2F2F;
      width: 138px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      font-weight: bold;
      cursor: pointer;
      background-color: rgba(239,47,47,0.05);
      color: #EF2F2F;
      margin-left: 40px;
      &:first-child{
        margin-left: 0;
      }
      &.deep{
        background-color: #EF2F2F;
        color: #fff;
      }
      &.distribution{
        width: 224px;
      }
      &.light{
        opacity: .6;
      }
    }
    .hoverGray {
      &:hover {
        background: #f7f1f1;
      }
    }
    .hoverLight {
      &:hover {
        background: #f14e4e;
      }
    }
    // 购买咨询
    .Purchase {
      width: 224px;
    }
    .isDeposit{
      width: 224px;
      height: 46px;
      color: #EF2F2F;
      font-weight: bold;
      border: 1px solid #EF2F2F;
      background-color: rgba(239,47,47,0.05);
      display: flex;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-weight: bold;
      font-size: 14px;
      .price{
        margin-left: 5px;
      }
      >span{
        font-size: 12px;
        color: #999;
      }
    }

    .collect-btn{
      width: 46px;
      height: 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 40px;
      span {
        font-size: 12px;
      }
      &:hover {
        color: #999;
        border: 1px solid #999999;
        margin-top: -1px;
      }
      /deep/.ivu-icon {
        cursor: pointer;
      }
    }
    .compare-btn {
      margin-left: 63px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      i {
        display: inline-block;
        width: 23px;
        height: 23px;
        background-image: url('../../assets/imgs/compare-btn.png');
        background-size: 100% 100%;
      }
      .active {
        background-image: url('../../assets/imgs/compare-btn-active.png') !important;
      }
      span {
        font-size: 12px;
      }
    }
  }

  /deep/.ivu-modal-wrap{
    img{
      width: 300px;
      height: 300px;
      margin: 50px auto 0;
      display: block;
    }
    .ivu-modal-content{
      background-color: #fff
    }
    .ivu-modal-footer{
      text-align: center;
      color: #000;
      font-size: 14px;
      border: none;
      padding-bottom: 20px;
    }
  }

</style>
