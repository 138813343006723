<template>
  <div class="detail-show">
    <div class="detail-left">
      <div class="detail-main-img">
        <CustomVideo
          v-if="videoImgFlag"
          :src="goodsData.picVideoUrl"
          :poster="goodsData.picVideoPicUrl"
        />
<!--        <img  v-else :src="$fillImgPath(mainImg)" alt="">-->
        <gree-vue-photo-zoom-pro v-else :high-url="$fillImgPath(mainImg)" :out-zoomer="true" :scale="2.5" :width="260" :height="260">
          <img  :src="$fillImgPath(mainImg)" alt="" class="detail-main-img-slot">
        </gree-vue-photo-zoom-pro>
      </div>
      <div class="detail-img-column" @mouseenter="showSlider = true" @mouseleave="showSlider = false">
        <ul class="detail-img-ul">
          <li class="detail-img-li" v-if="goodsData.picVideoUrl" @mouseenter="showMainVideo">
            <CustomVideo
              :src="goodsData.picVideoUrl"
              :poster="goodsData.picVideoPicUrl"
              :controls="false"
              :playFlag="false"
            />
          </li>
          <li class="detail-img-li" v-for="(item, index) in skuPicUrls" :key="index" @mouseenter="showMainImg(index)">
            <img :src="$fillImgPath(item)" alt="">
          </li>
        </ul>
        <div :class="['slider','top',showSlider?'showSlider':'']" @click="sliderClick('up')">
          <Icon type="md-arrow-dropup" size="20" color="#fff"/>
        </div>
        <div :class="['slider','bottom',showSlider?'showSlider':'']" @click="sliderClick('down')">
          <Icon type="md-arrow-dropdown" size="20" color="#fff"/>
        </div>
      </div>
    </div>
    <div class="detail-right">
      <div class="detail-title">
        <span class="detail-tag" v-if="goodsData.selfRunFlag == '20'">自营</span>
        <span class="detail-skuName">{{currentSkuInfos.skuName}}</span>
      </div>
      <div v-if="currentSkuInfos.skuAd" class="detail-ad">
        <span>{{currentSkuInfos.skuAd}}</span>
      </div>
      <!-- 秒杀/直降banner -->
      <div :class="['active-infos',isBegin?'begin':'']" v-if="showBanner">
        <div>
          <span v-if="!isBegin" class="active-title">{{activeInfo.activityType === '10'?'秒杀预告':'直降预告'}}</span>
          <img v-else :src="activeInfo.activityType === '10'?require('@/assets/imgs/detail/miaosha.png'):require('@/assets/imgs/detail/zhijiang.png')" alt="">
          <span v-if="!isBegin" class="banner-price"><i>¥</i>{{activeInfo.activityPrice.split('.')[0]}}<i v-if="activeInfo.activityPrice.split('.')[1]">.{{activeInfo.activityPrice.split('.')[1]}}</i></span>
        </div>
        <!-- 倒计时 -->
        <CustomCountDown
          :time="countDownTime"
          :isBegin="isBegin"
          @finished="countDownFinished"
        >
          <Icon type="md-alarm" :color="isBegin?'red':'white'" size="18"/>
        </CustomCountDown>
      </div>
      <!-- 其他 -->
      <div class="layout-outer">
        <!-- 价格 -->
        <div class="content-item white price" v-if="!businessGood">
          <span>价格</span>
          <div class="item-left">
            <div class="price-wrap">
              <!-- 价格banner -->
              <div class="active-banner" v-if="showBeginBanner">
                <div class="active-banner-item">
                  <span>{{activeInfo.activityType === '10'?'秒杀价':'限时特价'}}</span>
                  <span class="strong">
                    <i>¥</i>{{ activeInfo.activityPrice.split('.')[0]}}<i v-if="activeInfo.activityPrice.split('.')[1]">.{{ activeInfo.activityPrice.split('.')[1] }}</i>
                  </span>
                </div>
                <i>=</i>
                <div class="active-banner-item">
                  <span>日常价</span>
                  <span>¥{{currentSkuInfos.currentPrice}}</span>
                </div>
                <i>-</i>
                <div class="active-banner-item">
                  <span>限时立减</span>
                  <span>¥{{reducePriceNum}}</span>
                </div>
              </div>
              <!-- 其他价格 -->
              <div class="price-inner" v-else>
                <template v-if="unShelf">
                  <span class="business">暂无报价</span>
                </template>
                <template v-else>
                  <!-- <span v-if="isDeposit" class="isDeposit">订金</span> -->
                  <span class="current-price">
                    <i>¥</i>{{priceShow.layoutPrice&&priceShow.layoutPrice.split('.')[0]}}<i v-if="priceShow.layoutPrice&&priceShow.layoutPrice.split('.')[1]">.{{priceShow.layoutPrice.split('.')[1]}}</i>
                  </span>
                  <!-- 划线价 -->
                  <span v-if="showLinePrice" :class="['origin-price',isDeposit?'isDeposit':'']">
                    <i v-if="isDeposit">指导价</i>¥{{priceShow.linePrice}}
                  </span>
                  <!-- 普通直降划线价 -->
                  <span class="line-price" v-if="downTag && parseFloat(priceShow.linePrice) > parseFloat(priceShow.layoutPrice)">
                    ¥{{ priceShow.linePrice }}
                  </span>
                  <span v-if="downTag" class="down-tag">{{activeInfo.activityTag}}</span>
                </template>
              </div>
              <span v-if="+goodsData.salesVolume">已售:<i>{{ goodsData.salesVolume>9999?(parseInt((goodsData.salesVolume/10000) * 10) / 10).toFixed(1)+'万+':goodsData.salesVolume }}</i></span>
            </div>
          </div>
        </div>
        <!-- 优惠券 -->
        <div class="content-item white" v-if="couponList.length > 0">
          <span class="no-letter">优惠券</span>
          <div class="item-left">
            <Coupon
              :layoutCouponList="layoutCouponList"
              :couponList="couponList"
              :shopId='goodsData.shopId'
              :showCouponTitle="true"
            />
          </div>
        </div>
        <!-- 服务 -->
        <div class="content-item white serve" v-if="serviceArr.length > 0">
          <span>服务</span>
          <div class="item-left">
            <Badge>
              <img class="serve-img" src="@/assets/imgs/detail/mark.png" slot="count"  @mouseenter="showServePopup = true" @mouseleave="handleServeDialogLeave"/>
            </Badge>
            <ul class="serve-wrap">
              <li v-for="item in serviceArr" :key="item.serviceId">{{item.serviceName}}</li>
            </ul>
          </div>
          <!-- 服务浮层 -->
          <div class="serve-popup" v-show="showServePopup" @mouseenter="handleServeDialogEnter" @mouseleave="showServePopup = false; mouseCur = false">
            <h3 class="serve-title">服务说明</h3>
            <ul class="serve-ul">
              <li class="serve-li" v-for="item in serviceArr" :key="item.serviceId">
                <div>
                  <img src="@/assets/imgs/detail/fuwu.png" alt="">
                </div>
                <div class="serve-describe">
                  <span>{{item.serviceName}}</span>
                  <span>{{item.serviceDetail}}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 地址选择 -->
        <div class="content-item address">
          <span>送至</span>
          <div class="item-left">
            <Address
              v-if="goodsData.shopId"
              @changeAddressCallBack="checkAreaCallBack"
            />
            <span :class="['tip-txt',!this.noInventory&&!this.unShelf&&!this.distribution?'red':'']">{{ tipTxt }}</span>
          </div>
        </div>
        <!-- sku面板 -->
        <SkuPanel
          ref="SkuPanel"
          v-if="goodsData.productStatus == 4"
          :isBegin="isBegin"
          :unShelf="unShelf"
          :distribution="distribution"
        />
        <!-- 限购 -->
        <div class="content-item" v-if="showLimit">
          <span>限购</span>
          <span v-if="limitText">每人限购{{ activeInfo.personLimit}}件，超出限购数量不可购买</span>
          <span v-else>{{activeInfo.activityName}}</span>
        </div>
      </div>
      <!-- 底部按钮 -->
      <Footer
        :businessGood="businessGood"
        :isDeposit="isDeposit"
        :noInventory="noInventory"
        :unShelf="unShelf"
        :isBegin="isBegin"
        :distribution="distribution"
        @footerClick="handleFooterClick"
      />
      <Modal
        v-model="showCartDialog"
        :closable="false"
        :footer-hide="true"
      >
      <div class="cart-dialog">
        <img src="@/assets/imgs/detail/Frame@2x.png" />
        <div class="title">加入购物车成功</div>
        <div class="btn">
          <div @click="showCartDialog = false" class="left left-good">继续浏览</div>
          <div @click="goCart" class="right right-good">去购物车结算</div>
        </div>
      </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs'
import CustomVideo from './CustomVideo.vue'
import CustomCountDown from './CustomCountDown.vue'
import SkuPanel from './SkuPanel.vue'
import Footer from './Footer.vue'
import Coupon from '@/components/Coupon.vue'
import Address from '@/components/Address.vue'
import GreeVuePhotoZoomPro from './gree-vue-photo-zoom-pro'
// import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'

import{
  goodsService,
  doSeckill,
  getSeckillTokenResult,
  addCart,
  checkGoodStatus,
  checkInventory,
  checkArea
} from '@/api/api'

export default {
  name: 'ShowGoods',
  components:{
    CustomVideo,
    CustomCountDown,
    Footer,
    Coupon,
    Address,
    SkuPanel,
    GreeVuePhotoZoomPro
  },
  data () {
    return {
      videoImgFlag: false, //视频图片切换
      imgIndex: 0, //当前img索引
      // quantity: 1, //购买数量
      skuPicUrls:[], //images数组
      showSlider: false, //滑块展示
      scrollNumber:0, //滑块点击基数控制
      productNo: this.$route.query.productNo, //商品id
      serviceArr: [], //服务数据
      showServePopup: false, //服务浮层
      userId: this.userInfo?.userId, //用户id
      activeInfo: null,  //当前sku活动信息
      distribution: false, //配送区域校验
      countDownTime: 0,
      showCartDialog: false,
      mouseCur: false, // 鼠标当前位置
    };
  },
  computed: {
    ...mapState(['goodsData','currentSkuInfos','couponList','userInfo','sKillToken','userAddress']),

    mainImg(){
      if(this.skuPicUrls?.length > 0) return this.skuPicUrls[this.imgIndex]
    },

    // 优惠券
    layoutCouponList(){
      const data = this.couponList[0].couponCommodityDetailDataVOList
      data.map(item => {
        // couponType 1-满减券 2-现金券 3-折扣券 4-每满券
        item.layoutName = item.couponName
        // switch (item.couponType) {
        //   case 1:
        //     item.layoutName = `${item.description.slice(0,-2)}减${item.couponAmount}`
        //     break;
        //   case 2:
        //     item.layoutName = `现金券${item.couponAmount}`
        //     break;
        //   case 3:
        //     item.layoutName = `${item.couponAmount}折券`
        //     break;
        //   case 4:
        //     item.layoutName = `${item.description.slice(0,-2)}减${item.couponAmount}`
        //     break;
        //   default:
        //     break;
        // }
      })
      return data
    },
    // 限购
    showLimit(){
      /*
        1.personLimit -1 表示不限量
        2.过滤拼团
        3.秒杀/直降预告时，有商品限购活动，应展示商品限购信息，无商品限购活动，不应展示限购信息
      */
      const data = this.goodsData.activityInfoVO

      if(data?.activityType === '60' || data?.activityType === '30'){
        return data && data.personLimit && data.personLimit !== -1
      }else{
        return data && data.activityType !== '70' &&  data.personLimit && data.personLimit !== -1 && this.isBegin
      }
    },
    // 限购文本
    limitText(){
      const data = this.goodsData.activityInfoVO
      return !data.purchaseLimitActivityType || data.purchaseLimitActivityType == 10
    },
    // 活动是否开始
    isBegin() {
      const activeInfo = this.activeInfo
      if(!activeInfo) return false
      const val = dayjs(activeInfo?.serverTime).valueOf()
      >= dayjs(activeInfo?.beginTime).valueOf()
      && dayjs(activeInfo?.serverTime).valueOf()
      <= dayjs(activeInfo?.endTime).valueOf()
      return val
    },
    // 活动预告是否开始
    isBeginTrailer(){
      const activeInfo = this.activeInfo
      if(!activeInfo) return false
      const val = dayjs(activeInfo?.serverTime).valueOf()
      >= dayjs(activeInfo?.noticeTime).valueOf()
      && dayjs(activeInfo?.serverTime).valueOf()
      <= dayjs(activeInfo?.endTime).valueOf()
      && dayjs(activeInfo?.serverTime).valueOf()
      < dayjs(activeInfo?.beginTime).valueOf()
      return val
    },
    // 秒杀/直降banner 普通直降已开始不展示
    showBanner(){
      const activityType = this.activeInfo?.activityType
      let val = false
      if(activityType === '10'){
        val = true
      }else if(activityType === '20'){
        // straightDownType 10-普通直降 20-限时直降
        val = this.activeInfo.straightDownType === 10 ? !this.isBegin : this.isBegin
      }
      return val
    },
    // 秒杀/限时直降 -已开始
    showBeginBanner(){
      const activityType = this.activeInfo?.activityType
      return (activityType === '10' || (activityType === '20' && this.activeInfo.straightDownType === 20)) && this.isBegin
    },
    // 普通直降 -已开始
    downTag(){
      const activityType = this.activeInfo?.activityType
      return activityType === '20' && this.activeInfo.straightDownType === 10 && this.isBegin
    },
    // 商务合作商品
    businessGood(){
      return this.activeInfo?.activityType === '40'
    },
    // 定金商品
    isDeposit(){
      return this.activeInfo?.activityType === '30'
    },
    // 无货商品
    noInventory(){
      return this.currentSkuInfos?.skuInventory === 0
    },
    // 下架商品
    unShelf(){
      const arr = [1,2,3,5,6,10,20]
      return arr.includes(this.goodsData?.productStatus)
    },
    // 配送区域提示
    tipTxt(){
      const obj = {
        0:'该商品已下架',
        1:'无货，该商品暂时缺货',
        2:'超出配送范围',
        3:'有货'
      }
      if (this.unShelf) {
        return obj[0]
      }
      if (this.noInventory) {
        return obj[1]
      }
      if (this.distribution) {
        return obj[2]
      }
      if (!this.noInventory && this.userAddress.fullAddress) {
        return obj[3]
      }
    },
    // 价格展示
    priceShow(){
      const activityType = this.activeInfo?.activityType
      let layoutPrice,linePrice
      if(this.downTag){
        layoutPrice = this.activeInfo.activityPrice
        linePrice = this.currentSkuInfos.currentPrice
      }else{
        layoutPrice = this.currentSkuInfos.currentPrice
        linePrice = this.currentSkuInfos.originPrice
      }
      let price = {layoutPrice,linePrice}
      return price
    },
    // 限时立减价格
    reducePriceNum(){
      const data = (this.currentSkuInfos.currentPrice * 100 -  this.activeInfo.activityPrice * 100) / 100
      return Math.round(data) === data ? data : (Math.floor(data * 1000) / 1000).toFixed(2)
    },
    // 商品初始化活动状态  10-未开始;20-进行中;-10: 无活动
    pageActivityStatus() {
      const data = this.goodsData.activityInfoVO
      let status
      if (!data) {
        status = -10
      } else if (this.isBegin) {
        status = 20
      } else if (this.isBeginTrailer) {
        status = 10
      }
      return status
    },
    showLinePrice() {
      let activityList = ['30', '70'] // 定金|拼团活动
      let cal =  parseFloat(this.priceShow.linePrice) > parseFloat(this.priceShow.layoutPrice)
      return !activityList.includes(this.activeInfo?.activityType) && cal && !this.downTag && this.priceShow.linePrice
    }
  },
  created () {
    this.goodsServiceFn()
  },
  watch:{
    goodsData:{
      handler(val){
        if(Object.keys(val).length <= 0) return
        this.videoImgFlag = val.picVideoUrl ? true : false
        this.activeInfo = val.activityInfoVO ?? {}
        if(!this.showBanner) return
        this.getCountDownTimeFn()
      },
    },
    currentSkuInfos:{
      handler(val){
        if(!val.skuPicUrls) return
        this.skuPicUrls = val.skuPicUrls.filter(e => e !== 'undefined' && e !== '')
      },
      immediate:true
    }
  },
  methods: {
    ...mapActions(['loadGoodsInfo','addShoppingCart']),
    // 底部按钮
    handleFooterClick(type){
      if (!this.userInfo.userId) {
        this.$router.push({
          path: '/login',
          query: {
            back: window.location.href,
            action: type
          }
        })
        return
      }
      // 秒杀下单需校验用户资格-token
      type === 'add' ? this.addCart() : this.buyNow()
    },
    goCart() {
      this.showCartDialog = false
      setTimeout(() => {
        // 加延时是为了：https://blog.csdn.net/jibuyueqian/article/details/123110292
        this.$router.push('/cart')
      }, 150)
    },
    // 加入购物车
    async addCart(){
      try {
        await this.validateSecKill()
        const params = {
          skuId: this.currentSkuInfos.skuNo,
          userId: this.userInfo.userId,
          quantity: Number(this.$refs.SkuPanel.quantity), // 数量
          thumbUrl: this.currentSkuInfos.skuAdPicture,
          shopId: this.goodsData.shopId,
          cid: this.goodsData.cid,
          productId: this.goodsData.productNo,
          skuName: this.currentSkuInfos.skuName,
          skuAttr: this.currentSkuInfos.itemSaleAttributeList.map(attr => attr.attrValue).join('/') || '白色',
          shopName: this.goodsData.shopName,
          shopType: 20, // 店铺类型
          currentPrice: this.currentSkuInfos.currentPrice,
          origin: '0',
          pageActivityStatus: this.pageActivityStatus,
          activityType: this.goodsData.activityInfoVO
            ? this.goodsData.activityInfoVO.activityType
            : '',
          straightDownType:
            this.goodsData.activityInfoVO &&
            this.goodsData.activityInfoVO.activityType == 20
              ? this.goodsData.activityInfoVO.straightDownType
              : '',
        }
        const { result, data } = await addCart(params)
        if (result == 0){
          this.$parent.refreshCartNum()
          this.showCartDialog = true
          return
        }
        // 商品信息发生变化
        if (result == 1465) {
          setTimeout(() => {
            const data = {
              productNo: this.goodsData.productNo,
              skuNo: this.currentSkuInfos.skuNo,
              shopId: this.goodsData.shopId,
              userId: this.userInfo.userId,
            }
            this.loadGoodsInfo(data);
          }, 500)
        }
        // 超出限购数量 更新数量
        if (result == 1440) {
          this.$refs.SkuPanel.quantity = data.seckillPersonalLimit[0].inventory? data.seckillPersonalLimit[0].inventory : 1
        }
      }catch{
        console.log('秒杀校验失败');
      }
    },
    // 构造订单数据
    makeConfirmOrderData(type) {
      let list = [];
      const activityTypeList = ['30','40'] // 30 订金预购， 40 商务合作
      let obj = {
        shopId: this.goodsData.shopId,
        shopName: this.goodsData.shopName,
        shopType: 20,
        shopCouponId: "", // 店铺优惠券
        products: [
          {
            productId: this.goodsData.productNo,
            skuId: this.currentSkuInfos.skuNo,
            quantity: this.$refs.SkuPanel.quantity,
            skuName: this.currentSkuInfos.skuName,
            skuAttr: this.currentSkuInfos.itemSaleAttributeList.map(attr => attr.attrValue).join('/') || '白色',
            productImg: this.currentSkuInfos.skuAdPicture,
            price: activityTypeList.includes(this.activeInfo?.activityType)? this.activeInfo.activityPrice
            : (this.pageActivityStatus == 20 ? this.activeInfo?.activityPrice : this.currentSkuInfos.currentPrice),
            activityInfos:[{}],
            thirdPlatformCid: this.goodsData.cid
          }
        ]
      }
      if (this.activeInfo) {
         // 直降活动
        if (this.activeInfo.activityType == '20') {
          obj.products[0].activityInfos[0].straightDownType = this.activeInfo.straightDownType
        }
        // 秒杀活动 校验通过
        if (this.activeInfo.activityType == '10' && this.sKillToken) {
          obj.products[0].activityInfos[0].activityToken = this.sKillToken
        }
        obj.products[0].activityInfos[0].activityType = this.activeInfo.activityType
        obj.products[0].activityInfos[0].pageActivityStatus = this.pageActivityStatus;
        obj.products[0].activityInfos[0].activityId = this.activeInfo.activityId
      }

      // 2种情况删除activityInfos字段
      // 1、如果活动未开始则删除字段
      // 2、组合购下立即购买则删除字段
      if ((!activityTypeList.includes(this.activeInfo?.activityType) && this.pageActivityStatus != 20)) {
        delete obj.products[0].activityInfos
      }

      list.push(obj)
      this.$store.commit('SET_ORDER_INFO', {
        internalBuyActivityId: this.goodsData.internalActivityId,
        shops: list,
        shopCart: false,
        isSeckill: this.activeInfo.activityType == '10' ? true : false
      });
    },
    //立即购买
    async buyNow(){
       // 先校验商品状态
      const goodsStatus = await this.checkGoodStatus()
      if (!goodsStatus) return

      await this.validateSecKill()
      const query = {
        userReceiveAddress: {
          provinceCode: this.userAddress.provinceCode,
          cityCode: this.userAddress.cityCode,
          countyCode: this.userAddress.countryCode,
          townCode: this.userAddress.townCode
        },
        origin: 0,
        userId: this.userInfo.userId,
        activityId: this.activeInfo.activityId,
        activityType: this.activeInfo.activityType,
        queryList: [{
          skuId: this.currentSkuInfos.skuNo,
          shopId: this.goodsData.shopId,
          productId: this.goodsData.productNo,
          quantity: this.$refs.SkuPanel.quantity,
          baseShopId: this.goodsData.shopId,
        }]
      }
      this.makeConfirmOrderData('buyNow')
      const { result, data: resData } = await checkInventory(query)
      switch (result) {
        case 0:
          this.$router.push('/order')
          break;
        case 1440:
          // 超出个人限购数量 更新数量
          this.$refs.SkuPanel.quantity = resData.seckillPersonalLimit[0].inventory? resData.seckillPersonalLimit[0].inventory : 1
          break
        case 1465:
          setTimeout(() => {
            const data = {
              productNo: this.goodsData.productNo,
              skuNo: this.currentSkuInfos.skuNo,
              shopId: this.goodsData.shopId,
              userId: this.userInfo?.userId
            }
            this.loadGoodsInfo(data);
          }, 1000)
          break;
      }
    },
    //校验秒杀token
    validateSecKill(){
      // 秒杀活动且进行中才需校验
      if(this.activeInfo?.activityType !== '10' || !this.isBegin){
        return Promise.resolve(true)
      }else{
        return this.sKillToken ? this.getSeckillTokenResultFn() : this.doSeckillFn()
      }
    },
    // 请求秒杀token
    async doSeckillFn(){
      const query = {
        skuId: this.currentSkuInfos.skuNo,
        userId: this.userInfo?.userId,
        seckillActivityId: this.goodsData.activityInfoVO?.activityId,
        quantity: this.$refs.SkuPanel.quantity,
      }
      const {result,data} = await doSeckill(query)
      if(result === 0){
        this.$store.commit('SET_SKILL_TOKEN',data.seckillToken)
        return this.getSeckillTokenResultFn(data.seckillToken)
      }else{
        //秒杀活动未获取token,刷新页面
        const data = {
          productNo: this.goodsData.productNo,
          skuNo: this.currentSkuInfos.skuNo,
          shopId: this.goodsData.shopId,
          userId: this.userInfo?.userId
        }
        this.loadGoodsInfo(data);
        return Promise.reject()
      }
    },
    // 获取秒杀token结果
    async getSeckillTokenResultFn(){
      const query = {
        seckillToken: this.sKillToken,
        skuId: this.currentSkuInfos.skuNo,
        userId: this.userInfo?.userId,
        seckillActivityId: this.goodsData.activityInfoVO?.activityId,
      }
      const {result,data:isSuccess} = await getSeckillTokenResult(query)
      if(result === 0 && isSuccess){
        return Promise.resolve(true)
      }
      if(result === 2240){
        // token失效 清除token
        this.$store.commit('SET_SKILL_TOKEN','')
      }else if(result === 1458){
        // 商品库存不足：提示并刷新当前页
        window.location.reload()
      }
      return Promise.reject()
    },
    // 校验商品信息是否变化
    async checkGoodStatus(){
      let isPass = true
      const query = {
        skuId: this.currentSkuInfos.skuNo,
        shopId: this.goodsData.shopId,
        pageActivityStatus: this.pageActivityStatus,
        activityId: this.goodsData.activityInfoVO?.activityId,
        activityType: this.goodsData.activityInfoVO
          ? this.goodsData.activityInfoVO.activityType
          : '',
        straightDownType: this.goodsData.activityInfoVO
          ? this.goodsData.activityInfoVO.activityType == 20
            ? this.goodsData.activityInfoVO.straightDownType
            : ''
          : ''
      }
      const {result } = await checkGoodStatus(query)
      if(result === 1465){
        setTimeout(() => {
          const data = {
            productNo: this.goodsData.productNo,
            skuNo: this.currentSkuInfos.skuNo,
            shopId: this.goodsData.shopId,
            userId: this.userInfo?.userId
          }
          this.loadGoodsInfo(data);
        }, 1000)
      }
      if (result != 0) {
        isPass = false
      }
      return isPass
    },

    //配送区域校验
    async checkAreaCallBack(){
      if(Object.keys(this.userAddress).length === 0) return
      const query = {
        // 无四级地址townCode时取三级地址 countryCode
        cityCode: this.userAddress.townCode || this.userAddress.countryCode,
        storeId: this.goodsData.shopId,
        thirdPlatformCid: this.goodsData.cid
      }
      const { result, data: { exitShopTerritory, restrictedSalesArea }} = await checkArea(query)
      if (result === 0) {
        this.distribution = !exitShopTerritory || restrictedSalesArea
      }
    },

    // 倒计时时间
    getCountDownTimeFn(){
      const type = this.isBegin?'endTime':'beginTime'
      this.countDownTime = dayjs(this.activeInfo?.[type]).valueOf() - dayjs(this.activeInfo?.serverTime).valueOf()
    },

    //滑块点击
    sliderClick(type){
      /*
        *图片/视频少于4张不滑动
        *下滑时超出图片/视频数量不滑动
        *上滑时scrollNumber基数为0时不滑动
      */
      const len = this.goodsData.picVideoUrl?this.skuPicUrls.length + 1:this.skuPicUrls.length
      if(len <= 4 ) return
      if(type === 'down' && this.scrollNumber + 4 >= len) return
      if(type === 'up' && this.scrollNumber === 0) return

      const dom = document.getElementsByClassName('detail-img-ul')
      // 99 为img高度+padding高度的总值，布局更改 此处可能需要对应更改
      type === 'down' ? this.scrollNumber += 1 : this.scrollNumber -= 1
      const top = 99 * this.scrollNumber
      dom[0].style.top = `-${top}px`
    },
    //切换视频
    showMainVideo(){
      this.videoImgFlag = true
    },
    // 切换主图
    showMainImg (index) {
      this.videoImgFlag = false
      this.imgIndex = index;
    },
    //倒计时结束
    countDownFinished(){
      console.log('倒计时结束了');
      // 刷新页面
      this.$Message.success('活动已更新');
      const data = {
        productNo: this.goodsData.productNo,
        skuNo: this.currentSkuInfos.skuNo,
        shopId: this.goodsData.shopId,
        userId: this.userInfo?.userId,
        activeCouponFlag:true //活动刷新优惠券不请求
      }
      this.loadGoodsInfo(data);
    },
    //请求服务
    async goodsServiceFn(){
      const { result, data: { records } } = await goodsService({productNo: this.productNo})
      if (result !== 0) return
      this.serviceArr = records
    },
    handleServeDialogEnter() {
      this.showServePopup = true
      this.mouseCur = true
    },
    handleServeDialogLeave() {
      let timer = setTimeout(() => {
        if (this.mouseCur) return
        this.showServePopup = false
        this.mouseCur = false
        clearTimeout(timer)
      }, 200)
    }
  }
};
</script>

<style lang='less' scoped>
/******************商品图片及购买详情开始******************/

@w:100%;
@h:100%;

.detail-show {
  display: flex;
  justify-content: space-between;
}
.detail-left {
  display: flex;
  margin-right: 40px;
  img{
    width: @w;
    height: @h;
  }
}
.detail-main-img {
  width: 395px;
  height: 395px;
  box-shadow: 0px 0px 8px #ccc;
  cursor: pointer;
}

.detail-main-img-slot {
  width: 395px !important;
  height: 395px !important;
  box-shadow: 0px 0px 8px #ccc;
  cursor: move;
}

.detail-img-column {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 92px;
  height: 395px;
  background-color: #fff;
  margin-left: 8px;
  overflow: hidden;
  cursor: pointer;
}

.detail-img-ul {
  position: absolute;
  left: 0;
  top: 0;
  transition: top .3s ease;
  width: @w;
  img{
    width:@w;
    height:@h;
  }
}
.detail-img-li{
  width: 100%;
  height: 90px;
  margin-bottom: 9px;
  box-sizing: content-box;
  img {
    width: 100%;
    height: 100%;
  }
  &:hover{
    width: calc(100% - 2px);
    border: 1px solid rgb(229, 0, 17)
  }
}

.slider{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 100%;
  height: 15px;
  background-color: #000;
  opacity: 0;
  transition: opacity .3s;
  &.top{
    top: 0;
  }
  &.bottom{
    bottom: 0;
  }
  &.showSlider{
    opacity: .5;
  }
}

/*商品选购详情*/
.detail-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.detail-title {
  .detail-tag{
    font-size: 12px;
    background-color: #e4393c;
    color: #fff;
    border-radius: 3px;
    padding: 1px 14px;
    margin-right: 5px;
  }
  .detail-skuName{
    color: #333;
    font-size: 20px;
    font-weight: 700;
    line-height:28px;
    word-break: break-all;
  }
}

.detail-ad {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  word-break: break-all;
  margin-top: 8px;
}

.active-infos{
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  margin-top: 8px;
  color: #fff;
  background: rgba(245, 173, 173, 0.336);
  &.begin{
    background:url('~@/assets/imgs/detail/active-bg.png');
    background-size: 100% 100%;
  }
  .active-title{
    font-size: 20px;
    font-weight: 900;
    color: #EF2F2F;
  }
  img{
    width: 94px;
    height: 24px;
  }
  .banner-price{
    color: #EF2F2F;
    font-weight: bold;
    margin-left: 15px;
    font-size: 18px;
    i{
      font-size: 16px;
    }
  }
}

.content-item{
  padding: 10px 0 10px 15px;
  &.white{
    background-color: #fff;
  }
  &.serve{
    align-items: flex-start;
    padding-bottom: 0;
    position: relative;

    .serve-img{
      width: 12px;
      height: 12px;
    }
    /deep/.ivu-badge-count{
      top: -2px;
      right: 14px;
      cursor: pointer;
    }

    .serve-popup{
      position: absolute;
      left: 0px;
      top: 30px;
      width: 100%;
      background-color: #fff;
      border: 1px solid #999;
      z-index: 99999;
      .serve-title{
        margin-top: 20px;
        margin-left: 13px;
        font-family: PingFang SC Bold;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
      }

      .serve-ul{
        margin-top: 7px;
        margin-left: 28px;
        padding-bottom: 50px;
        font-size: 12px;
      }

      .serve-li {
        float: left;
        display: flex;
        align-items: center;
        margin-right: 41px;
        margin-bottom: 20px;
        div:nth-child(1) {
          margin-right: 12px;
        }
        img {
          width: 8px;
          height: 8px;
        }

        .serve-describe{
          display: flex;
          flex-direction: column;
          text-align: left;
          span{
            color: #666666;
            word-break: break-all;
            &:first-child{
              margin-bottom: 2px;
              color: #333;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  &.address{
    line-height: 28px;
    .tip-txt{
      margin-left: 10px;
      color: #999;
      &.red{
        color: #cc0000;
      }
    }
  }
  &.price {
    line-height: 28px;
  }
  .item-left {
    display: flex;
    align-items: center;
    margin-left: 66px;
    /deep/ .coupon-outer {
      margin:0;
    }
    /deep/ .ivu-badge-count {
      z-index: 1;
      top: -15px;
    };
  }
  > span:first-child {
    width: 40px;
    float: left;
    color: #999;
    text-align: justify;
    text-align-last: justify;
    &.no-letter{
      white-space: nowrap;
      letter-spacing: normal
    }
  }
}

.layout-outer{
  font-size: 13px;
  margin-top: 10px;
  .price-wrap{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    >span{
      color: #999;
    }
  }
  .price-inner{
    display: flex;
    align-items: center;
    color: #cc0000;
    font-weight: 700;
  }
  .business{
    font-size: 18px;
    color: #333;
  }
  .active-banner{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 312px;
    height: 65px;
    background:url('~@/assets/imgs/detail/price-bg.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
    &-item{
      display: flex;
      align-items: center;
      flex-direction: column;
      span{
        &:last-child{
          margin-top: 8px;
        }
        &.strong{
          font-size: 24px;
          font-weight: bold;
          i{
            font-size: 16px;
          }
        }
      }
    }
    >i{
      margin-top: 15px;
    }
  }
  .isDeposit{
    font-size: 20px;
    margin-right: 5px;
  }
  .current-price {
    color: red;
    font-size: 24px;
    font-weight: 400;
    i{
      margin-right: 4px;
      font-size: 18px;
    }
  }
  .line-price {
    margin-left: 8px;
    text-decoration: line-through;
    font-size: 14px;
    color: #999999;
  }
  .down-tag{
    display: block;
    min-width: 70px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    background:url('~@/assets/imgs/detail/down-bg.png') no-repeat;
    background-size: 100% 100%;
    color: #fff;
    padding-left: 5px;
    margin-left: 5px;
  }
  .origin-price{
    font-size: 16px;
    color: #999;
    margin-left: 20px;
    text-decoration: line-through;
    &.isDeposit{
      text-decoration:none;
      font-size: 14px;
    }
  }

  .serve-wrap{
    flex: 1;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    cursor: pointer;
    color: #333;
    li {
      font-size: 14px;
      margin: 0 15px 10px 0;
      &::before{
        content: '·';
        margin-right: 5px;
      }
    }
  }
}


.cart-dialog {
  text-align: center;
  padding: 40px 36px 32px;
  img {
    width: 40px;
     height: 40px;
  }
  .title {
    margin-top:16px;
    font-size: 18px;
    color:#333;
    font-weight: 700;
    font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB-Regular;
    text-align: center;
    color: #333333;
    line-height: 26px;
  }
  .btn {
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  .left {
    cursor: pointer;
    width: 168px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    opacity: 1;
    border: 1px solid #999999;
    border-radius: 5px;
    background: #cc0000;
    border-radius: 4px;
    color: #999999;
    margin-right: 24px;
    background: #999999;
  }
  .left-good {
    background: #fff;
  }
  .left:hover {
    background: #ef2f2f;
  }
  .left-good:hover {
    background: #ebebeb;
    border: 1px solid #cc0000;
    color: #cc0000;
  }
  .right {
    width: 204px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #cc0000;
    border-radius: 4px;
    color: #cc0000;
  }
  .right-good {
    background: #cc0000;
    border: none;
    color: #ffffff;
  }
  .right:hover {
    background: rgba(228,31,31,0.20);
    border: none;
  }
  .right-good:hover {
    background: #ef2f2f;
  }
}
}

/******************商品图片及购买详情结束******************/
</style>
